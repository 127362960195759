@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --primary: #7469B6;
  --secondary: #E1AFD1;
  --tertiary: #AD88C8;
  --dark: #26355D;
  --text: #FFE6E6;
}

.bg-primary-o {
  background-color: var(--primary);
}

.bg-dark-o {
  background-color: var(--dark);
}

.bg-secondary-o {
  background-color: var(--secondary);
}

.text-text {
  color: var(--text);
}

.text-secondary-o {
  color: var(--secondary);
}

.text-dark-o {
  color: var(--dark);
}

.text-tertiary-o {
  color: var(--tertiary);
}

.nav-link {
  background-color: var(--secondary);
  color: var(--dark);
  font-weight: bold;
  border-radius: 0px!important;
}

.nav-pills .nav-link.active {
  background-color: var(--dark)!important;
  color: var(--text);
}

.nav-link:hover {
  color: var(--text);
}

th:first-child, td:first-child {
  position: sticky;
  left: 0;
  background-color: #FFFFFF;
  z-index: 1;
}

.page-button {
  width: 2.5rem;
  height: 2.5rem;
  border: none;
  outline: none;
  background-color: #E0E0E0;
  color: var(--dark);
}

.page-button.active {
  background-color: var(--primary);
  color: var(--text);
}

.success-modal-backdrop,
.failure-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.success-modal,
.failure-modal {
  background-color: #fff;
  padding: 20px 30px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  text-align: center;
  max-width: 400px;
  width: 90%;
}

.success-icon,
.failure-icon {
  margin-bottom: 15px;
}

.success-modal h2,
.failure-modal h2 {
  margin: 0;
  font-size: 24px;
}

.success-modal h2 {
  color: #4CAF50;
}

.failure-modal h2 {
  color: #F44336;
}

.success-modal p,
.failure-modal p {
  font-size: 16px;
  color: #333;
  margin: 10px 0 20px;
}

.close-btn {
  background-color: #4CAF50;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.close-btn:hover {
  background-color: #45A049;
}

.failure-modal .close-btn {
  background-color: #F44336;
}

.failure-modal .close-btn:hover {
  background-color: #E53935;
}